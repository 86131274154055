<!-- 等级编辑弹窗 -->
<template>
  <div class="ele-body">
    <el-card shadow="never">
      <div style="margin-bottom: 20px;" class="el-dialog__header">
        <span class="el-dialog__title">参数配置</span>
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="82px">
        <el-form-item
          label="训练目标:"
          prop="training">
          <el-input
            v-model="form.training"
            placeholder="请输入训练目标"
            clearable
            :rows="3"
            type="textarea"/>
        </el-form-item>

        <el-form-item
          label="课时安排:"
          prop="arrange">
          <el-input
            v-model="form.arrange"
            placeholder="请输入课时安排"
            clearable
            :rows="3"
            type="textarea"/>
        </el-form-item>
        <el-form-item
          label="测试提示:"
          prop="demand">
          <tinymce-editor class="tinymce-editor" v-model="form.demand"/>
        </el-form-item>
        <el-form-item
                label="做题提示:"
                prop="reminder">
          <el-input
                  v-model="form.reminder"
                  placeholder="请输入做题提示"
                  clearable
                  :rows="3"
                  type="textarea"/>
        </el-form-item>
        <el-form-item
          label="阅读时长:"
          prop="read_time">
          <el-input
            v-model="form.read_time"
            placeholder="请输入阅读时长"
            clearable/>
        </el-form-item>
        <el-form-item
          label="答题时长:"
          prop="answer_time">
          <el-input
            v-model="form.answer_time"
            placeholder="请输入答题时长"
            clearable/>
        </el-form-item>
<!--        <el-form-item
          label="题目数量:"
          prop="num">
          <el-input
            type="number"
            v-model="form.num"
            placeholder="请输入题目数量"
            clearable/>
        </el-form-item>-->
        <el-form-item label="是否分组:">
          <el-radio-group v-model="form.is_group" @change="changeRadio">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-for="(item, i) in form.roleList"
          :key="i"
          prop="play_speed"
        >
          <el-row type="flex">
            <el-col :span="4">等级: {{ item.name }} </el-col>

            <el-col :span="5">
              <el-form-item label="题目数量:">
                <el-input
                  placeholder="请输入题目数量"
                  v-model="item.num"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="每组数量:">
                <el-input
                  placeholder="请输入每组数量"
                  v-model="item.group_num"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
<!--        <el-form-item
          label="每组数量:"
          prop="group_num"
          v-show="isShowNum"
        >
          <el-input
            type="number"
            v-model="form.group_num"
            placeholder="请输入每组题目数量"
            clearable/>
        </el-form-item>-->
        <!--<el-form-item
                label="每组题目数量:"
                prop="flag">
            <el-input
                    type="number"
                    v-model="form.flag"
                    placeholder="请输入题目数量"
                    clearable/>
        </el-form-item>-->
        <el-form-item label="备注:">
          <el-input
            v-model="form.remark"
            placeholder="请输入备注"
            :rows="4"
            type="textarea"/>
        </el-form-item>
      </el-form>
      <div>
        <el-button style="float: right;margin-bottom: 20px;"
                   type="primary"
                   @click="save"
                   :loading="loading">保存
        </el-button>
      </div>

    </el-card>
  </div>
</template>

<script>
  import TinymceEditor from '@/components/TinymceEditor';
export default {
  name: 'configEdit',
  props: {},
  components: {TinymceEditor},
  data() {
    this.$http['get']('/Admin/readConfigEdit').then(res => {
      this.data = res.data.data;
      // 如果后台的配置项没有 roleList 调用查看用户等级接口 选取默认的列表
      this.queryRoles(this.data.roleList);
      if (!this.data) {
        this.data = {'is_group': 0};
      } else {
        this.isUpdate = true;
        this.isShowNum = this.data.is_group
       // this.groupNum = this.data.group_num
      }
      this.form = Object.assign({}, this.data);
    });
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        training: [
          {required: true, message: '请输入训练目标', trigger: 'blur'}
        ],
        arrange: [
          {required: true, message: '请输入课程安排', trigger: 'blur'}
        ],
        read_time: [
          {required: true, message: '请输入阅读时长', trigger: 'blur'}
        ],
        answer_time: [
          {required: true, message: '请输入答题时长', trigger: 'blur'}
        ],
        /*num: [
          {required: true, message: '请输入题目数量', trigger: 'blur'}
        ]*/
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 是否显示每组数量
      isShowNum: 0,
      // 每组数量
      groupNum: 0,
      oldList:[]
    }
  },
  methods: {

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        console.log(this.form)
        if (valid) {
          this.loading = true;
          // 判断是否分组 选择否 条件成立
          if(!this.form.is_group){
            this.form.group_num = 0
          }
          this.$http[this.isUpdate ? 'put' : 'post']('/Admin/saveReadConfig', this.form).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    // 改变分组状态
    changeRadio(e) {
      this.isShowNum = e
     // this.$set(this.form,'group_num', this.groupNum)
      if(!e){
        this.form.roleList.map((item)=>{
          item.group_num = 0
        })
        return
      }
      this.form.roleList = JSON.parse(JSON.stringify(this.oldList))
    },
    queryRoles(roleList) {
      if (!roleList) {
        this.$http
          .get("/Admin/level")
          .then((res) => {
            if (res.data.code === 200) {
              this.form.roleList = res.data.data.map((obj) => {
                return { name: obj.name, id: obj.id, num: 1,group_num: 0 };
              });
              this.form = Object.assign(
                this.data,
                {
                  // 时间和字数 numberTime
                  roleList: this.form.roleList,
                },

              );
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
        return
      }
      this.oldList = JSON.parse(JSON.stringify(roleList))
    },
  }
}
</script>

<style scoped>
</style>
